<template>
  <div class="d-flex flex-column full-height">
    <div class="flex-grow-0">
      <order-stepper v-model="step" />
      <step-header
        :step="step"
        :title="'order.parameterStep.chooseParameter'"
        :sub-title="'order.parameterStep.chooseParametersDescription'"
      ></step-header>
    </div>
    <v-row class=" fill-height d-flex flex-column">
      <v-col class="d-flex shrink">
        <v-container class="pl-10">
          <v-row>
            <v-col cols="7">
              <v-select
                class="mt-0"
                :label="$t('order.parameterStep.filterCategories')"
                chips
                hide-details
                :items="availableFilterCategories"
                v-model="activatedFilters"
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="removeFilterCategory(item)"
                  >
                    <span>{{ item.name }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-flex grow overflow-y-auto">
        <v-container class="pl-10">
          <v-row>
            <v-col
              v-for="product in filteredProducts"
              :key="product.id"
              cols="3"
              class="d-flex"
            >
              <v-card elevation="4">
                <v-img :src="product.image" height="120px"></v-img>
                <v-card-title class="py-0">
                  {{ product.id.toUpperCase() }}
                  <v-spacer />
                  <v-switch
                    v-model="order.products"
                    :value="product.id"
                    :disabled="product.id === 'rgb'"
                  />
                </v-card-title>
                <v-card-subtitle>
                  {{ product.name }}
                  <span v-if="product.unit">[{{ product.unit }}]</span>
                </v-card-subtitle>
                <v-card-text>
                  {{ product.description }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="d-flex shrink ">
        <v-row
          no-gutters
          class="pa-5"
          style="background-color: white"
          align="center"
        >
          <v-col cols="auto">
            <v-btn text @click="previousStep">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t("order.back") }}
            </v-btn>
          </v-col>
          <v-col class="d-flex flex-row-reverse" cols="auto">
            <v-btn color="secondary" text @click="cancel"
              >{{ $t("cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-chip
              v-for="product in order.products"
              :key="product"
              class="mr-2"
              close
              :disabled="product == 'rgb'"
              @click:close="removeProductById(product)"
            >
              {{ product.toUpperCase() }}
            </v-chip>
          </v-col>
          <v-spacer />
          <v-col class="d-flex flex-row-reverse ml-4" cols="auto">
            <v-btn
              color="primary"
              rounded
              @click="nextStep"
              :disabled="order.products.length === 0"
              >{{ $t("continue") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OrderStepper from "@/core/components/order/OrderStepper";
import StepHeader from "@/core/components/order/StepHeader.vue";

export default {
  name: "ParameterStep",
  components: { StepHeader, OrderStepper },
  props: {
    value: Number,
    order: Object
  },
  data() {
    return {
      step: this.value,
      availableFilterCategories: [
        { id: "wc", name: this.$t("order.parameterStep.wc") },
        { id: "ws", name: this.$t("order.parameterStep.ws") },
        { id: "other", name: this.$t("order.parameterStep.other") }
      ],
      activatedFilters: ["wc", "ws", "other"],
      // Search at https://visibleearth.nasa.gov/
      products: [
        {
          id: "abs",
          name: "Total Absorption",
          description: this.$t("productDescriptions.abs"),
          unit: "1/m",
          category: "wc",
          image: require("/src/core/assets/product_images/ABS_poa.jpg")
        },
        {
          id: "cdm",
          name: "CDOM, Yellow substance",
          description: this.$t("productDescriptions.cdm"),
          unit: "1/m",
          category: "wc",
          image: require("/src/core/assets/product_images/CDM.jpg")
        },
        {
          id: "chl",
          name: "Chlorophyll",
          description: this.$t("productDescriptions.chl"),
          unit: "µg/l",
          category: "wc",
          image: require("/src/core/assets/product_images/CHL_malawi.jpg")
        },
        {
          id: "hab",
          name: "Harmful Algae Bloom Indicator",
          description: this.$t("productDescriptions.hab"),
          unit: null,
          category: "wc",
          image: require("/src/core/assets/product_images/HAB_cahora.jpg")
        },
        {
          id: "sdd",
          name: "Secchi Disc Depth",
          description: this.$t("productDescriptions.sdd"),
          unit: "m",
          category: "wc",
          image: require("/src/core/assets/product_images/SDD_cahora.jpg")
        },
        {
          id: "sia",
          name: "Sum of Inorganic Absorbers",
          description: this.$t("productDescriptions.sia"),
          unit: "1/m",
          category: "wc",
          image: require("/src/core/assets/product_images/SIA_poa.jpg")
        },
        {
          id: "soa",
          name: "Sum of Organic Absorbers",
          description: this.$t("productDescriptions.soa"),
          unit: "1/m",
          category: "wc",
          image: require("/src/core/assets/product_images/SOA.jpg")
        },
        {
          id: "tsc",
          name: "Trophic State Index",
          description: this.$t("productDescriptions.tsc"),
          unit: null,
          category: "wc",
          image: require("/src/core/assets/product_images/TSC_kwania.jpg")
        },
        {
          id: "tur",
          name: "Turbidity",
          description: this.$t("productDescriptions.tur"),
          unit: "NTU",
          category: "wc",
          image: require("/src/core/assets/product_images/TUR_darwin.jpg")
        },
        {
          id: "tsm",
          name: "Total suspended matter",
          description: this.$t("productDescriptions.tsm"),
          unit: "mg/l",
          category: "wc",
          image: require("/src/core/assets/product_images/TSM_cahora.jpg")
        },
        {
          id: "sst",
          name: "Sea Surface Temperature",
          description: this.$t("productDescriptions.sst"),
          unit: "°C",
          category: "ws",
          image: require("/src/core/assets/product_images/SST.jpg")
        },
        {
          id: "wex",
          name: "Water Body Extent",
          description: this.$t("productDescriptions.wex"),
          unit: null,
          category: "ws",
          image: require("/src/core/assets/product_images/WEX_cahora.jpg")
        },
        {
          id: "rgb",
          name: "True Color Image",
          description: this.$t("productDescriptions.rgb"),
          unit: null,
          category: null,
          image:
            "https://eoimages.gsfc.nasa.gov/images/imagerecords/61000/61624/France.A2002226.1100.1km.jpg"
        }
      ],
      newSelectedProducts: []
    };
  },
  computed: {
    filteredProducts() {
      return this.products.filter(product => {
        if (product.category === null) {
          return this.activatedFilters.includes("other");
        } else {
          return this.activatedFilters.includes(product.category);
        }
      });
    }
  },
  methods: {
    nextStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("input", this.step + 1);
    },
    previousStep() {
      this.$emit("updateOrder", this.order);
      this.$emit("input", this.step - 1);
    },
    cancel() {
      this.$router.push({ name: "Regions" });
    },
    removeFilterCategory(item) {
      this.activatedFilters.splice(this.activatedFilters.indexOf(item.id), 1);
    },
    removeProductById(id) {
      const index = this.order.products.indexOf(id);
      this.order.products.splice(index, 1);
    }
  },
  created() {
    if (!this.order.products.includes("rgb")) {
      this.order.products.push("rgb");
    }
  }
};
</script>

<style scoped>
.child-flex > *,
.flex {
  flex: 1;
  max-width: 100%;
}
</style>
